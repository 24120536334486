import React, { useState, useEffect } from 'react';
import { Button, Skeleton } from "@mui/material";
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { IoCloseOutline } from "react-icons/io5";
import { FaRegFileAlt } from "react-icons/fa";
import { FileIcon, defaultStyles } from 'react-file-icon';
import { RiImageAddLine } from "react-icons/ri";
import { ApiRequest } from 'GlobalFunctions';

// Add this before the ImageUploaderProps interface
declare module 'react' {
    interface InputHTMLAttributes<T> extends HTMLAttributes<T> {
        pc?: string;
    }
}

interface ImageUploaderProps {
    image: string;
    setImage: (image: string) => void;
    setFiles: (files: File[]) => void;
    className?: string;
    disabled?: boolean;
    maxFiles?: number; // Add maxFiles prop
}

export default function FileUploader({ 
    image, 
    setImage, 
    setFiles, 
    className, 
    disabled,
    maxFiles = 1 // Default to 99 if not specified
}: ImageUploaderProps) {
    const [fileNames, setFileNames] = useState<{display: string, full: string}[]>([]);
    const [currentFiles, setCurrentFiles] = useState<File[]>([]);
    const [storageConfiguration, setStorageConfiguration] = useState<any>({});
    const [isLoading, setIsLoading] = useState(true); // Add loading state

    const get_storage_configuration = async () => {
        setIsLoading(true);
        try {
            await ApiRequest({
                url: "/stores/getStorageConfiguration",
                method: "get",
                query: {
                    order_id: localStorage.getItem('order_id'),
                },
                setResponse: (response: any) => {
                    setStorageConfiguration(response);
                },
            });
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        get_storage_configuration();
    }, []);

    useEffect(() => {
        const handlePaste = (e: ClipboardEvent) => {
            const items = e.clipboardData?.items;
            if (!items) return;

            // Check if there are any image items in the clipboard
            const hasImageItems = Array.from(items).some(item => item.type.indexOf('image') !== -1);
            
            // Only prevent default if we have images (allows normal text paste)
            if (hasImageItems) {
                e.preventDefault();
                
                const files: File[] = [];
                for (let i = 0; i < items.length; i++) {
                    const item = items[i];
                    if (item.type.indexOf('image') !== -1) {
                        const file = item.getAsFile();
                        if (file) files.push(file);
                    }
                }

                if (files.length > 0) {
                    handleFiles(files);
                }
            }
        };

        document.addEventListener('paste', handlePaste);
        return () => document.removeEventListener('paste', handlePaste);
    }, [currentFiles]);

    const formatFileName = (fileName: string) => {
        if (fileName.length <= 15) return fileName;
        return `${fileName.substring(0, 15)}...`;
    };

    const handleFiles = (inputFiles: FileList | File[]) => {
        const newFilesArray = Array.from(inputFiles);
        
        // Check file sizes
        const maxSizeMB = storageConfiguration.max_file_size || 10; // fallback to 10MB if not set
        const maxSizeBytes = maxSizeMB * 1024 * 1024;
        
        const oversizedFiles = newFilesArray.filter(file => file.size > maxSizeBytes);
        if (oversizedFiles.length > 0) {
            alert(`The following files exceed the ${maxSizeMB}MB limit:\n${oversizedFiles.map(f => f.name).join('\n')}`);
            return;
        }

        const updatedFiles = [...currentFiles, ...newFilesArray];
        
        // Check if total files would exceed limit
        if (updatedFiles.length > maxFiles) {
            alert(`You can only upload a maximum of ${maxFiles} files.`);
            return;
        }
        
        setCurrentFiles(updatedFiles);
        setFiles(updatedFiles);
        
        // Update file names by appending new ones
        const newNames = newFilesArray.map(file => ({
            display: formatFileName(file.name),
            full: file.name
        }));
        setFileNames(prev => [...prev, ...newNames]);
        
        // Set the first file's URL as the main preview if there wasn't one before
        if (!image && newFilesArray.length > 0) {
            setImage(URL.createObjectURL(newFilesArray[0]));
        }
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files && files.length > 0) {
            handleFiles(files);
        }
    };

    const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        const files = e.dataTransfer.files;
        if (files && files.length > 0) {
            handleFiles(files);
        }
        e.currentTarget.classList.remove("dropzone_active");
    };

    const handleDelete = (index: number) => {
        // Remove file from arrays
        const updatedFiles = currentFiles.filter((_, i) => i !== index);
        const updatedNames = fileNames.filter((_, i) => i !== index);
        
        setCurrentFiles(updatedFiles);
        setFileNames(updatedNames);
        setFiles(updatedFiles);

        // Update main image if needed
        if (index === 0 && updatedFiles.length > 0) {
            setImage(URL.createObjectURL(updatedFiles[0]));
        } else if (updatedFiles.length === 0) {
            setImage('');
        }
    };

    const getFileExtension = (fileName: string): string => {
        const extension = fileName.split('.').pop()?.toLowerCase() || '';
        return extension;
    };

    const getFileType = (fileName: string): string => {
        const extension = getFileExtension(fileName);
        const type = extension === 'jpg' || extension === 'jpeg' || extension === 'png' ? 'Image' : 'File';
        return type;
    };

    return (
        <div className='files-uploader-container'
            onDrop={handleDrop}
            onDragOver={(e) => {
                e.preventDefault();
                e.currentTarget.classList.add("dropzone_active");
            }}
            onDragLeave={(e) => {
                e.preventDefault();
                e.currentTarget.classList.remove("dropzone_active");
            }}
        >
            <div className="files-uploader-list-container" style={fileNames.length > 0  ? { display: 'flex' } : {display: 'none'}}>
                {fileNames.map((fileName, index) => (
                    <div key={index} className="files-uploader-name">
                        
                        <div className="extension_icon">
                            <FileIcon 
                                extension={getFileExtension(fileName.full).toLowerCase()} 
                                {...(defaultStyles as any)[getFileExtension(fileName.full)]}
                                labelUppercase={true}
                            />
                        </div>
                        <div className="file-name-type">
                            <div className='file-name'>{fileName.display}</div> 
                            <div className='file-type'>{getFileType(fileName.full)}</div>
                        </div>
                        <IoCloseOutline 
                            className="delete-icon"
                            size={20}
                            onClick={() => handleDelete(index)}
                        />
                    </div>
                ))}
            </div> 
            {isLoading ? (
                <div>
                    <div className='upload-icon-container'>
                        <RiImageAddLine size={24} className="upload-icon" />
                    </div>
                </div>
            ) : (
                <label id="files-uploader-drop-div" 
                    className={fileNames.length > 0 ? 'files-uploader-button-on-hover' : ''} 
                    style={disabled ? { display: 'none' } : {}}
                >
                    <div className="fileUploaderButton">
                        {fileNames.length === 0 ? (
                            <div className='upload-icon-container'>
                                <RiImageAddLine size={24} className="upload-icon" />
                            </div>
                        ) : null}
                        <div className='files-uploader-button-label'>
                            <div className='files-uploader-button-label_1'>
                                {maxFiles === 1 ? 
                                    'Add image, gif, or video' : 
                                    `Add images, gifs, and videos - up to ${maxFiles}`
                                }
                            </div>
                            <div className='files-uploader-button-label_2'>Drag + Drop / Copy + Paste (up to {storageConfiguration.max_file_size}MB)</div>
                        </div>
                        <input
                            type="file"
                            pc="file"
                            multiple
                            hidden
                            onChange={handleFileChange}
                            disabled={isLoading}
                        />
                    </div>
                </label>
            )}
        </div>
    );
}
