import { ApiRequest, ShowLoader } from 'GlobalFunctions';
import { useEffect, useState } from 'react';
import getOrderDetails from '../Orders/getOrderDetails';
import {ProjectConfig} from 'Global';

export default function Bag() {

    const [productQuantity, setProductQuantity] = useState(0);

    useEffect(() => {
        buildBag();
    }, []);

    return null;
}



const buildBag = async () => {
    
    const productQuantity = await getProductQuantity();


        const bagButton = document.querySelector("[pc='bag']");
        if (bagButton) {
            bagButton.addEventListener("click", async () => {
                showBag();
            });
        }
    
}


/*=========================================================
GET BAG TEMPLATE
=========================================================*/
const getBagTemplate = async () => {
    await ApiRequest({
        url: "/templates/getTemplate",
        method: "get",
        query: {
            template_name: "bag",
        },
        setResponse: (response: any) => {
            const div = document.createElement('div');
            div.id = 'bag_popup_container';
            div.classList.add('hidden');
            div.innerHTML = response.template;
            
            // Store webflow pages data for navigation
            let webflowPages = response.webflow_pages || {};
            
            document.body.appendChild(div);

            //search dinamically for pc-icon="icon_name" and replace it with the icon
            const icons = document.querySelectorAll('img[pc-icon]');
            icons.forEach((iconElement) => {
                const iconName = iconElement.getAttribute('pc-icon');
                if (iconName) {
                    const newSrc = `${ProjectConfig.api_url}/assets/icons/library/${iconName}.svg`;
                    iconElement.setAttribute('src', newSrc);
                }
            });

            /*=========================================================
            ADD LISTENER FOR BAG BACKGROUND
            =========================================================*/
            const background = document.querySelector('[pc="bag-background"]');
            background?.addEventListener("click", hideBag);

            //bag-close
            const closeButton = document.querySelector('[pc="bag-close"]');
            closeButton?.addEventListener("click", hideBag);

            //bag-view-bag-button
            const viewBagButton = document.querySelector('[pc="view-cart"]');
            viewBagButton?.addEventListener("click", async () => {
                if (webflowPages && webflowPages.cart && webflowPages.cart.slug) {
                    window.location.href = '/' + webflowPages.cart.slug;
                } else {
                    window.location.href = '/pennicart-cart';
                }
            });

            //bag-checkout-button
            const checkoutButton = document.querySelector('[pc="view-checkout"]');
            checkoutButton?.addEventListener("click", async () => {
                if (webflowPages && webflowPages.checkout && webflowPages.checkout.slug) {
                    window.location.href = '/' + webflowPages.checkout.slug;
                } else {
                    window.location.href = '/pennicart-checkout';
                }
            });
        },
    });
}


/*=========================================================
GET PRODUCT QUANTITY
=========================================================*/
export async function getProductQuantity() {
    let productQuantity = 0;
    await ApiRequest({
        url: "/orders/getProductQuantity",
        method: "get",
        query: {
            order_id: localStorage.getItem('order_id'),
        },
        setResponse: (response: any) => {
            const cartProductsCount = document.querySelector('[pc="bag-count"]');
            if (cartProductsCount) {
                cartProductsCount.innerHTML = response.quantity;
            }
            localStorage.setItem('productsQuantity', response.quantity);
            if (response.quantity === 0) {
                hideBag()
            }
        },
    });

    return productQuantity;
}

/*=========================================================
HIDE BAG
=========================================================*/
export async function hideBag() {
    const bag = document.querySelector("#bag_popup_container");
    if (bag) {
        bag.classList.add('hidden');
    }
}

/*=========================================================
SHOW BAG
=========================================================*/
export async function showBag (action = null) {

    const bag_heading = document.querySelector('[pc="bag-heading"]');
    if (bag_heading) {
        bag_heading.innerHTML = 'Your Bag';
    }
    
    const productQuantity = localStorage.getItem('productsQuantity') || 0;

        ShowLoader(true);
        await getBagTemplate();

        const productItem = document.querySelector('[pc="product-item"]');
        if(Number(productQuantity) == 0){
            //hide product-item 
            
            if (productItem) {
                productItem.classList.add('hidden');
            }
        }
        else{
            if (productItem) {
                productItem.classList.remove('hidden');
            }
        }

        const bag = document.querySelector("#bag_popup_container");
        if (bag) {
            const cart: any = await getOrderDetails({ page: "bag" });
            
                bag.classList.remove('hidden');
            
            
        }
        else{
            console.error('Bag template not found');
        }

        ShowLoader(false);
        
    
}
